.Appjj {
    width: 100%;
   
    display: flex;
    justify-content: center;
    align-items: center;

   
  }
    
  table {
     
    border: 1px solid black;
    width: 650px;
    height: 150px;
  }
    
  th {
    text-align: center;
    border-bottom: 1px solid black;
  }
    
  td {
    text-align: center;
    border-right: 1px solid black;
  }

  .tablecenter{
    margin-left: 10%;
  }

.tableheight{
    height: 18px;
}


  .onlineimg {
    margin-right: 15%;
}

.textctr{
text-align: center;
}


.column {
  float: left;
  width: 33.33%;
  padding: 10px;
  height: 300px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.tablecenter{
  margin-left: 94px;
}

.tdborder{
  border-right: 1px solid black;
}

.tabbortop{
  border-top: 1px solid black;
}

.bottomcontent{
  margin-top: 10%;
  margin-left: 70%;
}

.marleft{
  margin-left: 70%;
}

.billhead{
  margin-right: 50%;
}