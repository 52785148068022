.Formcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: aqua;
}

.Formname {
    height: 600px;
    width: 990px;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding-top: 120px;
    padding-bottom: 20px;
    border-radius: 8px;
    background-color: white;
}

.content {
    padding-left: 42%;
    padding-right: 12%;
    margin-top: -250px;
    margin-bottom: 860px;
}

.form-title {
    text-align: center;
    margin-bottom: 1em;
    font-size: 24px;
    color: rgb(34, 34, 34);
    font-weight: 800;
}

label {
    font-size: 14px;
    font-weight: 600;
    color: rgb(34, 34, 34);
}

.jfaimage {
    width: 34%;
    margin: auto;
    margin-right: 180px;
}


.image {
    margin-top: 100px;
    margin-right: 60px;
}


.dashboard .info-card h6 {
    font-size: 28px;
    color: #012970;
    font-weight: 700;

    padding: 0;
}


.button {
    margin-top: 40px;
}




.cardproperty {
    margin-top: 20px;
}

.btn btn-primary {
    margin-right: 30px;


}