

.hello{
    width: 350px;
    height: 50px;
    border-color: purple;
    background-color: white;
    border-radius: 15px;
    font-family: sans-serif;
    font-size: 18px;
    margin-left: 600px;
}

.jobbutton{
        margin-left: 30rem;
}

.jobicon{
    font-size: 4rem;
}



.section-title h2{
    font-family: sans-serif;
    color: #28395A;
    font-size: 50px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 95px;
    

}
.section-title span{
    font-size: 14px;
    color: #FB246A;
    font-family: sans-serif;
    margin: 0px 0px 28px;
    
}
.empdashcen{
    margin-top: 10%;
}