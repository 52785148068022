/* .paginate{ text-align: center; }
ul.pagination {

    margin-right: 10px !important;
}
.pagination{
    font-family: 'Ubuntu', sans-serif;
    display: inline-flex;
    position: relative;
}
.page-margin {
    margin-right: 15px;
}
.pagination li a.page-link{
    line-height: 41px;
    height: 42px;
    width: 42px;
    padding: 0;
    margin: 0 5px;
    border: none;
    border-radius: 50% !important;
    overflow: hidden;
    position: relative;
    color: #fff;
    background-color: #6d2e46;
    font-size: 20px;
    font-weight: 600;
    z-index: 1;
    transition: all 0.3s ease 0s;
}
.pagination li a.page-link:hover,
.pagination li a.page-link:focus,
.pagination li.active a.page-link:hover,
.pagination li.active a.page-link{
    color: #222;
    background: transparent;
    box-shadow: 0 0 10px #a26769;
}
.pagination li a.page-link:before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    border: 5px solid #a26769;
    border-top-color: transparent;
    border-right-color: transparent;
    border-radius: 50%;
    opacity: 0;
    transform: rotate(155deg);
    transition: all 0.3s ease 0s;
}
.pagination li a.page-link:hover:before,
.pagination li a.page-link:focus:before,
.pagination li.active a.page-link:before{
    opacity: 1;
    transform: rotate(-15deg);
}
@media only screen and (max-width: 480px){
    .pagination{
        font-size: 0;
        border: none;
        display: inline-block;
    }
    .pagination li{
        display: inline-block;
        vertical-align: top;
        margin: 0 0 10px;
    }
} */


/*globalfilter*/

span.filterbox{
  font-weight: 500;
  font-size: large;
}

nav.paginate.page_numbers {
  margin-right: auto;
}

ul.pagination.first_last{
  margin-right: auto;
  padding-right: 539px;
}
span.pageno{
  margin-right: auto;
    padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
    margin-top: 4px;
}

.goto_page{
  margin-right: auto; 
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
}
/* input.gotopage_number {
  border-radius: 50px;
  padding-left: 10px;
} */

.showpagesize {
  margin-right: auto;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 
}

select.showpagecss {
 
  height: 75%;
}