.container{
    padding-top: 50px;
    

}


    


    
.li{
    font-size: 16px;
    font-family: sans-serif;
}


.flex{
    margin-top: 25px;
    /* margin-left: -300px; */

    background-color:white;
    border: 1px solid gray;
    height:100%;
    width:20%;
}

.any{
    border: 1px solid black;
    padding: 5px;
}
.exp{
  font-size: 20px;
  font-family: sans-serif;  
  margin-top: 10px;
  margin-top: 45px;
}
.full{
    font-size: 14px;
    font-family: sans-serif;
    color:#8B92DD;
    margin-top: 150px;
}

.check1{
   margin-left: -20px;
   margin-bottom: -25px;
   font-size: 16px medium;
   font-family: sans-serif;



}


.orderlist{
    font-size: 16px;
    font-family: sans-serif;
    color: #666666;
}
.order2{
    font-size: 16px;
    font-family: sans-serif;
    color: #666666;
    margin-top: 5px;
}

.filter{
    font-size: 20px;
    font-family: sans-serif;  
    margin-top: 95px;
}

h4{
    font-weight: 500;
    font-style: normal;
}
.hi{
    border:1px solid #ededed;
    
   width:80%;
    padding: 15px 25px 25px 5px;
    margin: 0px 0px 50px;
    padding: 30px 19px 30px 16px;
    color: #212529;
}
.aaa{
    padding: 36px 30px;
    display:flex;
}






/* new */



.nice-select {
    -webkit-tap-highlight-color: transparent;
    background-color: #fff;
    border-radius: 5px;
    border: solid 1px #e8e8e8;
    box-sizing: border-box;
    clear: both;
    cursor: pointer;
    display: block;
    float: left;
    font-family: inherit;
    font-size: 14px;
    font-weight: normal;
    height: 42px;
    line-height: 40px;
    outline: none;
    padding-left: 18px;
    padding-right: 30px;
    position: relative;
    text-align: left !important;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    width: auto;
}

.single-job-items {
    padding: 36px 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
}
 .job-items {
    display: flex;
    flex-wrap: wrap;
}
.job-tittle{

    box-sizing: border-box;
    display: block;
    font-family: Muli,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    text-size-adjust: 100%;
    line-height: 24px;
    width:447.712;
    color:#212529;
}
.ul{
    padding-inline-start: 40px;
    color:#212529;
    font-size: 16px;
}
.items-link a{
    color:#8B92dd;
    display: block;
    border: 1px solid #8B92DD;
    border-radius: 30px;
    padding: 4px 33px;
    text-align: center;
    margin-bottom: 25px;

}
.single-job-items .job-tittle ul li{
    display: inline-block;
    margin-right: 48px;
    font-size: 15px;
    color: #808080;
    line-height: 1.8;
}
.single-job-items .job-tittle a h4{
    color: #28395a;
    font-size: 24px;
    margin-left: 25px;

}

.img{
/* .img, svg{ */
    height: 100px;
    width:100px;
}
.job-listing-area .count-job{
    display: flex;
     justify-content: space-between; 
    flex-wrap: wrap;
    
}
 /* .select-job-items {
    width:100px;
    height: 30px;
    background: #FFFFFF;
    padding:10px 12px;
    color: red;
    line-height: 6px;
    border: 1px solid #ededed;
    border-radius: 5px;

} */
/* 
.select{
    width:100px;
    height: 30px;
    background: #5d5d5d;
    padding:10px 12px;
    color: red;
    line-height: 6px;
    border: 1px solid #5d5d5d;
    border-radius: 5px;
    

}  */
.job-listing-area .count-job span {
    padding-right: 15px;
    color: #1e2147;
    font-size: 16px;
}
.job-listing-area .count-job span{
    padding-right: 15px;
    color:#1e2147;
    font-size: 16px;
}
/* .nice-select{
    width: 100px;
    height: 30px;
    padding: 10px 12px;
    color: #5d5d5d;
    line-height: 6px;
    border: 1px solid #ededed;
    border-radius: 5px;
} */
.job-listing-area .count-job span{

    padding-right: 15px;
}

.filterjobimg{
    width: 25%;
    margin-left: 35%;
  }